<template>
  <div class="background-container">
    <v-container>
      <v-card>

    <v-breadcrumbs :items="[
      { text: 'Solutions', exact: true, to: { name: 'solutions' } },
      { text: 'OnCourse365: year-round racing', disabled: true },
    ]" />
    <div :class="` ${$vuetify.breakpoint.xs?'':''}`">
      <h1><strong>OnCourse365:</strong> year-round racing</h1>
      <p>
        Take your on-course race and make it relevant 365 days a year! Allow participants to use their favorite app or GPS device to track their result.
      </p>
    </div>


    <div class="pb-6 pb-md-12">
      <v-row >
        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(0)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-watch-fitness</v-icon></v-card-text>
            <v-card-title class="headline">All major fitness trackers supported</v-card-title>
            <v-card-text>
              Allow your athletes to use the app or device they already love! 
              We support {{ providers.getSupportedProviders().join(', ') }}.
              No need to install or maintain expensive timing hardware.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(1)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-route</v-icon></v-card-text>
            <v-card-title class="headline">Verify the correct course is followed</v-card-title>
            <v-card-text>
              The sodisp platform will automatically analyze any uploaded activity and match it with your courses. Only tracks which followed the course from start to finish will qualify for the leaderboard.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(2)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-medal</v-icon></v-card-text>
            <v-card-title class="headline">Become relevant Year-Round</v-card-title>
            <v-card-text>
              Generate additional revenue by allowing participants to complete your trail event every day of the year. 
              Award (virtual) badges and (real) medals to motivate athletes to join your trail and be part of your community.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">
          <v-card :color="$helpers.getGradientColor(3)">
            <v-card-text class="pb-0"><v-icon size="100">fadl fa fa-share-all</v-icon></v-card-text>
            <v-card-title class="headline">Generate Shareable Content</v-card-title>
            <v-card-text>
              Our fully customizable leaderboards not only look beautiful but also invite participants to share their results. 
              Each result can be liked, commented on and participants can upload their selfies to show on their personal result.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-muted text-center">
          <div><v-icon class="my-6" color="grey">fal fa-chevron-double-down</v-icon></div>
          Scroll down for more features.
        </v-col>
      </v-row>
    </div>


    <div class="secondary white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Make your event year-round relevant</h2>
            <h1 class="subtitle">Use sodisp to create engaging and beautiful experiences!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/request-demo">Request Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

    <!--
    <h2>Explore Year-round trails</h2>
    <p>These are a few of the year-round trails already on sodisp.</p>
    <EventGrid :eventIds="['diablo-6-peaks', 'zoom-san-francisco-run-on-course-racing']"  />
    -->

    <div :class="`mt-10 text-center ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Discover our key features</h1>
      <p>
        Next to our <router-link to="/features">impressive feature set</router-link> we have developed special features for year-round trails.
      </p>
    </div>
    <v-container class="features">
      <v-row>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fab fa-strava</v-icon>
          <h4>Auto-sync with Garmin, Strava, Fitbit, Polar and more</h4>
          <p>
            Activities tracked with Garmin, Polar, Suunto, Fitbit or COROS devices and the Strava app are automatically synced and added to results making it extremely easy for your participants to make every km/mile count!
          </p>

          <p>
            Next to our native integrations we also allow for uploading any gpx file for results scoring and verification.
          </p>
          <p>
            As event organizer you have access to our powerful EventManager interface to review and manually approve (or reject) individual results.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-route</v-icon>
          <h4>Follow official course and geo-fences</h4>
          <p> 
            GPS tracks are automatically compared with the official track. Only activities following the entire course will qualify for the leaderboard.
          </p>
          <p>
            Allow participants to download the track and import it into their GPS watch so they can follow it during their trail!
          </p>
          <p>
            When participants are allowed to plan their own track you can use geo-fences to include only results from within a specific geographic area in the leaderboards.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create location based badges for Points of Interest on the course to unlock special awards when a summit is climbed.
          </p>
          <p>
            Easily customize the layout or even use custom images to make the badges unique to your challenge. You can optionally enable and customize email messages to be sent whenever a badge is awarded and view overall statistics of each badge on your race result page.
          </p>
        </v-col>
                
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-file-certificate</v-icon>
          <h4>Virtual Bibs and Finisher Certificates</h4>
          <p>
            Virtual Bibs and Finisher Certificates are two more pieces of shareable content to further improve user engagement during each challenge. Both features are also available for in-person events.
          </p>
          <p>
            Both the Virtual Bib and Finisher Certificate can be fully customized with event and sponsor logos, fonts, custom fields, and colors to create unique design to fit with the event experience.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-list</v-icon>
          <h4>Connects with any registration platform</h4>
          <p>
            Connect the registration platform you already use with your sodisp event to monetize your brand and sell registration tickets year-round.
          </p>
          <p>
            Easily load your registration data to create official results and allow only registered participants to join your year-round trail.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-trophy</v-icon>
          <h4>Combine race-weekend with year-round results</h4>
          <p>
            Upload (at no extra cost) your official race results to the same challenge so you have all your results in the same look and feel.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design</h4>
          <p> 
            Make it yours by customizing the design of your virtual event/challenge. Easily upload your logo and artwork to make your virtual event look beautiful.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-cogs</v-icon>
          <h4>API integrations</h4>
          <p> 
            Use our REST APIs for custom integration with your systems and services. We provide APIs for various integration scenarios such as automated result or registration data sync. We have Excel import/export options available to help you get started without development work needed.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Only pay for what you use</h4>
          <p> 
            Get started with sodisp for free. <!-- See our <router-link to="/pricing">simple and fair pricing</router-link> for details.  --><router-link to="/contact">Contact us</router-link> to discuss how sodisp can help your event become relevant year-round.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="{name: 'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

      </v-card>
    </v-container>
  </div>
</template>


<script>
import siteData from '@/data/site.json'
import providers from '@/util/oauthProviders.js'
import EventGrid from "@/components/EventGrid.vue";

export default {
  name: "Contact",
  props: {
  },
  components: {
    EventGrid,
  },
  data: function() {
    return {
      siteData: siteData,
      providers: providers,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }

  .background-container { background-image: url(https://sodisp.imgix.net/content/trail-running.jpg); }

</style>